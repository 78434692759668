import {FormControl, FormHelperText, OutlinedInput} from "@mui/material";

export const CustomTextField = ({
                                    label,
                                    value,
                                    handleChange,
                                    placeholder,
                                    type = 'text',
                                    name
                                }) => {
    return (<FormControl fullWidth variant="outlined">
        <FormHelperText sx={{
            fontFamily: 'Outfit',
            color: 'black',
            fontSize: '12px',
            m: 0
        }} id="outlined-weight-helper-text">{label} <sup className={'head_mark'}>*</sup> </FormHelperText>
        <OutlinedInput
            value={value}
            onChange={handleChange}
            inputProps={{
                'aria-label': label,
            }}
            placeholder={placeholder}
            type={type}
            name={name}
        />
    </FormControl>)
}