import {motion} from 'framer-motion';

export const GradientSpan = ({children, gradient, style}) => {

    return (<motion.span style={{
        background: gradient,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        backgroundSize: '500% auto',
        '-webkit-background-clip': 'text',
        'background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        ...style
    }} animate={{
        backgroundPosition: ['0% 50%', '100% 0', '0% 50%'],
    }}
                         transition={{repeat:Infinity,duration: 10,     repeatType: "reverse",
                             times: [0, 0.2, 1]}}
    >
        {children}
    </motion.span>)
}