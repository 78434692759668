import {Box, Button, CircularProgress, IconButton, Modal, Stack, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {CustomTextField} from "./CustomTextField";
import {useFormik} from "formik";
import axios from "axios";
import querystring from 'query-string'
import {useState} from "react";
import Lottie from "react-lottie";
import animationData from '../assets/lottie/success.json';
import {useTheme} from "@emotion/react";

export const TryDemoModal = ({
                                 showModal, setShowModal
                             }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            company_name: '',
            business_email: ''

        },
        onSubmit: values => {
            if(values.first_name === ''){
                alert("Fields are empty")
                return
            }

            setLoading(true)
            let url = 'https://api.neurotaskai.com/lead-generate?'
            let data = querystring.stringify({
                'business_mail': values.business_email,
                'last_name': values.first_name,
                'first_name': values.last_name,
                'company_name': values.company_name
            });
            axios.get(url + data).then(() => {
                setLoading(false)
                setSuccess(true)
            }).catch(() => {
                setLoading(false)
                setShowModal(false)
                alert('Error');
            })
        }
    });
    return (<Modal open={showModal} onClose={() => setShowModal(false)}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                [theme.breakpoints.down('md')]: {
                    width: '90%',
                },
                bgcolor: '#fff',
                borderRadius: '10px',
                p: 4,
                border: 'none',
            }}>
                {success ? <>
                    <Lottie width={'50%'} options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}/>
                    <Typography variant={'h4'} sx={{
                        fontFamily: 'Outfit',
                        textAlign: 'center',
                    }}>
                        Thanks for your interest!
                    </Typography>
                </> : <>
                    <Box>
                        <Stack direction={'row'} justifyContent={'space-between'} mb={1} alignItems={'center'}>
                            <Typography variant={'h4'} sx={{
                                fontFamily: 'Outfit',
                                pb: 1,
                                color: 'var(--orange)'

                            }}>Get a Demo</Typography>
                            <IconButton onClick={() => setShowModal(false)}>
                                <Close/>
                            </IconButton>
                        </Stack>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            pb: 2,
                            color: 'rgba(0,0,0,0.74)',
                            fontSize: 14
                        }} variant={'h6'}>Learn how intent can enhance your sales performance and create a delightful
                            shopping experience for your customers.</Typography>
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack mt={2} direction={
                            'column'} spacing={2}>
                            <CustomTextField label={'First Name'} value={formik.values.first_name} name={'first_name'}
                                             handleChange={formik.handleChange} placeholder={'Enter First Name'}/>
                            <CustomTextField label={'Last Name'} value={formik.values.last_name} name={'last_name'}
                                             handleChange={formik.handleChange} placeholder={'Enter Last Name'}/>
                        </Stack>
                        <Box mt={3}>
                            <CustomTextField label={'Company Name'} name={'company_name'}
                                             value={formik.values.company_name} handleChange={formik.handleChange}
                                             placeholder={"Enter Company Name"}/>
                        </Box>

                        <Box my={3}>
                            <CustomTextField label={'Business Email'} name={'business_email'}
                                             value={formik.values.business_email} type={'email'}
                                             handleChange={formik.handleChange}
                                             placeholder={"Enter Your Business Mail"}/>
                        </Box>

                        <Box my={3}>
                            <Typography>
                                By providing this information, you agree that we may process your personal data in accordance with our Privacy Policy.
                            </Typography>
                        </Box>

                        <Button type={'submit'} fullWidth variant={'contained'} sx={{
                            py: 1,
                            fontSize: '12px',
                            bgcolor: 'var(--orange)',
                            '&:hover': {
                                bgcolor: 'var(--orange)',
                            }
                        }}>
                            Submit
                        </Button>
                    </form>
                    <Modal open={loading}>
                        <CircularProgress sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'var(--orange)'
                        }}/>
                    </Modal></>}
            </Box>
        </Modal>
    )
}