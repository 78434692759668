import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import img from './assets/features/img.png';
import img2 from './assets/features1/Frame 14.png';
import img3 from './assets/features1/Frame 15.png';
import img4 from './assets/features1/Frame 16.png';
import img5 from './assets/features1/Frame 17.png';
import {useEffect, useState} from "react";
import AOS from "aos";
import {TryDemoModal} from "./components/TryDemoModal";
import {GradientSpan} from "./components/GradientSpan";
import {createTheme, responsiveFontSizes} from "@mui/material";
import {ThemeProvider} from "@emotion/react";

function App() {
    const [showModal, setShowModal] = useState(false);

    let theme = createTheme({
        breakpoints: {
            values: {
                xs: 320,
                sm: 481,
                md: 1025,
                lg: 1281,
                xl: 2450,
            }
        }
    });
    theme = responsiveFontSizes(theme);

    useEffect(() => {
        AOS.init();
    }, [])

    useEffect(() => {
        window.onscroll = function () {
            myFunction()
        };
        // Get the navbar
        var navbar = document.getElementById("navbar");
        // Get the offset position of the navbar
        var sticky = navbar.offsetTop;

        // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function myFunction() {
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }
    }, [])

    return (
        <>
            <ThemeProvider theme={theme}>
                <section className="navbar">
                    <div className="max_width mobile_active" id="navbar">
                        <div className="nav_logo">
                            <svg width="365" height="78" viewBox="0 0 365 78" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_140_194)">
                                    <path
                                        d="M43.929 56.526C40.804 56.526 37.9342 55.7396 35.3249 54.1719C32.7103 52.6198 30.6217 50.5365 29.054 47.9219C27.4967 45.3125 26.7207 42.4375 26.7207 39.2969C26.7207 36.1719 27.4967 33.3073 29.054 30.6927C30.6217 28.0833 32.7103 26.0365 35.3249 24.5469C37.9342 23.0469 40.804 22.2969 43.929 22.2969C47.0645 22.2969 49.9395 23.0469 52.554 24.5469C55.1634 26.0365 57.2103 28.0833 58.6999 30.6927C60.1999 33.3073 60.9499 36.1719 60.9499 39.2969C60.9499 42.4375 60.1999 45.3125 58.6999 47.9219C57.2103 50.5365 55.1634 52.6198 52.554 54.1719C49.9395 55.7396 47.0645 56.526 43.929 56.526Z"
                                        fill="#FD5C63"/>
                                    <path
                                        d="M116.931 22.7285C115.483 22.7285 114.17 22.3535 112.993 21.6035C111.811 20.8431 110.889 19.8118 110.222 18.5202C109.556 17.2181 109.222 15.8431 109.222 14.3952C109.222 13.0618 109.566 11.8223 110.264 10.666C110.957 9.51497 111.894 8.62435 113.076 7.99935C114.253 7.36393 115.54 7.04102 116.931 7.04102C118.389 7.04102 119.686 7.4056 120.826 8.12435C121.977 8.84831 122.899 9.82227 123.597 11.041C124.29 12.2493 124.639 13.5775 124.639 15.0202C124.639 16.4108 124.29 17.6973 123.597 18.8743C122.899 20.0566 121.962 20.9941 120.785 21.6868C119.602 22.3848 118.316 22.7285 116.931 22.7285ZM110.951 26.8952H123.014V75.041H110.951V26.8952Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M147.206 26.8953V31.7912C149.69 29.7391 152.049 28.1974 154.289 27.1662C156.523 26.1401 158.914 25.6245 161.456 25.6245C164.466 25.6245 167.227 26.2912 169.727 27.6245C172.237 28.9578 174.237 30.8849 175.727 33.3953C177.211 35.8953 177.956 38.8068 177.956 42.1245V75.0412H165.893V52.2912C165.893 48.6662 165.711 45.7651 165.352 43.5828C164.987 41.4058 164.216 39.7078 163.039 38.4995C161.857 37.2912 160.086 36.687 157.727 36.687C155.737 36.687 153.94 37.2495 152.331 38.3745C150.732 39.4891 149.477 41.1349 148.56 43.312C147.654 45.4787 147.206 48.1401 147.206 51.2912V75.0412H135.164V26.8953H147.206Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M190.995 37.3328H184.829V26.8953H190.995V9.12451H203.058V26.8953H210.224V37.3328H203.058V75.0412H190.995V37.3328Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M239.505 76.312C234.489 76.312 230.072 75.1922 226.255 72.9578C222.447 70.7235 219.505 67.6766 217.421 63.812C215.338 59.937 214.296 55.6401 214.296 50.9162C214.296 46.5151 215.322 42.3849 217.38 38.5203C219.432 34.6453 222.348 31.5308 226.13 29.1662C229.906 26.8068 234.302 25.6245 239.317 25.6245C244.328 25.6245 248.776 26.7443 252.651 28.9787C256.526 31.2183 259.531 34.312 261.671 38.2703C263.807 42.2287 264.88 46.7235 264.88 51.7495C264.88 52.8328 264.848 53.7391 264.796 54.4578H225.984C226.593 57.9735 228.119 60.7078 230.567 62.6662C233.026 64.6245 236.005 65.6037 239.505 65.6037C242.223 65.6037 244.593 65.187 246.609 64.3537C248.635 63.5099 250.739 62.1453 252.921 60.2703L261.526 67.4162C256.026 73.3485 248.682 76.312 239.505 76.312ZM252.734 44.937C251.953 42.161 250.369 39.9578 247.984 38.3328C245.593 36.6974 242.765 35.8745 239.505 35.8745C236.473 35.8745 233.843 36.6349 231.609 38.1453C229.369 39.661 227.682 41.9266 226.546 44.937H252.734Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M286.544 26.8953V31.7912C289.028 29.7391 291.388 28.1974 293.627 27.1662C295.862 26.1401 298.252 25.6245 300.794 25.6245C303.805 25.6245 306.565 26.2912 309.065 27.6245C311.575 28.9578 313.575 30.8849 315.065 33.3953C316.549 35.8953 317.294 38.8068 317.294 42.1245V75.0412H305.232V52.2912C305.232 48.6662 305.049 45.7651 304.69 43.5828C304.325 41.4058 303.555 39.7078 302.377 38.4995C301.195 37.2912 299.424 36.687 297.065 36.687C295.075 36.687 293.278 37.2495 291.669 38.3745C290.07 39.4891 288.815 41.1349 287.898 43.312C286.992 45.4787 286.544 48.1401 286.544 51.2912V75.0412H274.502V26.8953H286.544Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M330.334 37.3328H324.167V26.8953H330.334V9.12451H342.396V26.8953H349.563V37.3328H342.396V75.0412H330.334V37.3328Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M116.702 23.3768C115.119 23.3768 113.666 22.981 112.348 22.1893C111.025 21.3976 109.973 20.3455 109.181 19.0226C108.389 17.7049 107.994 16.2622 107.994 14.6893C107.994 13.106 108.389 11.658 109.181 10.3351C109.973 9.01742 111.025 7.98096 112.348 7.23096C113.666 6.48096 115.119 6.10596 116.702 6.10596C118.27 6.10596 119.712 6.48096 121.035 7.23096C122.353 7.98096 123.389 9.01742 124.139 10.3351C124.889 11.658 125.264 13.106 125.264 14.6893C125.264 16.2622 124.889 17.7049 124.139 19.0226C123.389 20.3455 122.353 21.3976 121.035 22.1893C119.712 22.981 118.27 23.3768 116.702 23.3768Z"
                                        fill="#FD5C63"/>
                                    <path
                                        d="M355.637 75.3099C354.053 75.3099 352.6 74.9141 351.282 74.1224C349.959 73.3307 348.907 72.2786 348.116 70.9557C347.324 69.638 346.928 68.1953 346.928 66.6224C346.928 65.0391 347.324 63.5911 348.116 62.2682C348.907 60.9505 349.959 59.9141 351.282 59.1641C352.6 58.4141 354.053 58.0391 355.637 58.0391C357.204 58.0391 358.647 58.4141 359.97 59.1641C361.288 59.9141 362.324 60.9505 363.074 62.2682C363.824 63.5911 364.199 65.0391 364.199 66.6224C364.199 68.1953 363.824 69.638 363.074 70.9557C362.324 72.2786 361.288 73.3307 359.97 74.1224C358.647 74.9141 357.204 75.3099 355.637 75.3099Z"
                                        fill="#FD5C63"/>
                                    <path
                                        d="M18.231 -0.342773V9.96973H11.4601V67.8656H18.231V78.1781H0.230957V-0.342773H18.231Z"
                                        fill="#4B4244"/>
                                    <path
                                        d="M69.6274 67.8656H76.3983V9.96973H69.6274V-0.342773H87.6483V78.1781H69.6274V67.8656Z"
                                        fill="#4B4244"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_140_194">
                                        <rect width="365" height="78" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <ul className="nav_tab_container">
                            <li className="navs buy_template"><a onClick={() => setShowModal(true)}> Get a Demo</a></li>
                        </ul>
                        <div className="menu-btn nav_tab_container" id="menu-btn">
                            {/*<i className="fas fa-bars"/>*/}
                            <div className="navs buy_template"><a onClick={() => setShowModal(true)}> Get a Demo</a></div>
                        </div>

                    </div>
                    <div className="mobile_container">
                        <div className="nav_logo">
                            <img src="./assets/logo.png" alt=""/>
                            {/*    <div className="close_nav">*/}
                            {/*        <i className="fa-solid fa-xmark "/>*/}
                            {/*    </div>*/}
                        </div>
                        <ul className="mobile_navbar">
                            <li className="m_navs buy_template"><a onClick={() => setShowModal(true)}> Get a Demo</a></li>
                        </ul>
                    </div>

                </section>
                <section className="home">
                    <div className="max_width center">
                        <div className="top_container">
                            <div className="home_heading" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="1100">
                                Turning customers&nbsp;
                                <GradientSpan
                                    gradient={'linear-gradient(-45deg,#615aff,#ff4994,#933ae3,#b01b83)'}
                                >curiosity</GradientSpan><br/> into purchase decision
                            </div>
                            <div className="sub_heading" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="1200"
                            >
                                Delight your customers with an AI-powered assisted shopping experience seamlessly embedded
                                within your current product&nbsp;<span
                                className={'head_mark'}>search&nbsp;</span>&nbsp;and&nbsp;<span
                                className="head_mark">discovery</span>&nbsp;journey.
                            </div>
                            <div className="home_button" data-aos="fade-up" data-aos-duration="1000"
                                 data-aos-easing="ease-in-back"
                            >
                                <a onClick={() => setShowModal(true)} className="get_start">
                                    Get a Demo&nbsp;&nbsp;
                                    <span className="active_get_start">
                        <i className="fa-solid fa-circle-arrow-right"/>
                    </span>
                                </a>
                            </div>
                        </div>
                        <figure className="home_img"
                                data-aos="fade-up" data-aos-easing="ease-in-back"
                                data-aos-duration="300"
                                data-aos-anchor-placement="top">
                            <video className={"video_src"} autoPlay loop muted playsInline>
                                <source src="https://storagegaspher01.blob.core.windows.net/global/17-4.mp4"
                                        type="video/mp4"/>
                            </video>

                        </figure>
                        <div className="sub_heading" data-aos="fade-up" data-aos-easing="ease-in-back"
                             data-aos-duration="600">
                            Conversion and customer retention flourish when customers feel understood
                        </div>
                    </div>
                </section>
                <section className="features" id="features">
                    <div className="max_width center">
                        <div className="feature_head_container center">
                            <div className="feature_heading" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="1100">
                                We built the&nbsp;<span className="head_mark">AI powered Search & Discovery</span>
                                &nbsp;infrastructure so you don’t have to
                            </div>

                        </div>
                        {/*<div className="container " data-aos="fade-up" data-aos-easing="ease-in-back"*/}
                        {/*     data-aos-duration="1100">*/}
                        {/*    <div className="col1 w-50">*/}
                        {/*        <div className="emojie">*/}
                        {/*            ⭐️*/}
                        {/*        </div>*/}
                        {/*        <h3>*/}
                        {/*            Understands what customers are looking for and drive immediate revenue.*/}
                        {/*        </h3>*/}
                        {/*    </div>*/}
                        {/*    <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"*/}
                        {/*         data-aos-duration="1200">*/}
                        {/*        /!*<img className="w-100" src={img} alt=""/>*!/*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="container">
                            <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="800">
                                    <img className="w-100" src={img2} alt=""/>
                                <div className="head_container">
                                    <h3>Understand User Intent</h3>
                                    <p>Comprehending natural language queries for understanding customers comprehensive needs
                                    </p>
                                </div>
                            </div>
                            <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="900">
                                <img className="w-100" src={img3} alt=""/>
                                <div className="head_container">
                                    <h3>Recommendation with a reason
                                    </h3>
                                    <p>Providing clear explanations behind product recommendations to enhance transparency and decision-making.
                                    </p>
                                </div>

                            </div>
                            <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="1000">
                                <img className="w-100" src={img4} alt=""/>
                                <div className="head_container">
                                    <h3>Product comparison with right tradeoff</h3>
                                    <p>Comparing products based on optimal balance between features, benefits, and compromises.</p>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="800">
                                <img className="w-100" src={img4} alt=""/>
                                <div className="head_container">
                                    <h3>Follow up for better purchase decisioning</h3>
                                    <p>Enabling instant inquiries and seamless follow-up questions for swift assistance
                                    </p>
                                </div>
                            </div>
                            <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="900">
                                <img className="w-100" src={img3} alt=""/>
                                <div className="head_container">
                                    <h3>Effortless setup, Seamless scaling
                                    </h3>
                                    <p>Seamless integration in minutes, no complex setup; effortlessly scales to handle any traffic volume </p>
                                </div>

                            </div>
                            <div className="col2 w-50" data-aos="fade-up" data-aos-easing="ease-in-back"
                                 data-aos-duration="1000">
                                <img className="w-100" src={img5} alt=""/>
                                <div className="head_container">
                                    <h3>Analytics for insight</h3>
                                    <p>Unlock valuable insights to understand your customers' true preferences and desires.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="footer_nav center">
                    <div className="max_width">
                        <div className="bottom_container">
                            <div className="footer_logo">
                                © 2024 intent
                            </div>
                        </div>
                    </div>
                </section>
                <TryDemoModal showModal={showModal} setShowModal={setShowModal}/>
            </ThemeProvider>

        </>
    );
}

export default App;
